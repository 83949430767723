import store from '../store'
import { lazyLoadView } from './lazyloadview'

export default [
  //ROUTES WHEN LOGGED OUT
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('../pages/Landing/Home.vue')),
    meta: {
      title: 'Gloednieuw overhoorprogramma om gratis woordjes online te leren en overhoren',
      showFooter: true,
      hideSubbar: true,
      navbarTopShow: true,
      hideSidebar: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('../pages/Landing/Login.vue')),
    meta: {
      title: 'Inloggen',
      showFooter: false,
      hideSubbar: true,
      navbarTopShow: true,
      hideSidebar: true
    }
  },
  {
    path: '/requestpassword',
    component: () => lazyLoadView(import('../pages/Landing/RequestPassword.vue')),
    meta: {
      title: 'Wachtwoord vergeten',
      requiresAuth: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/resetpassword/:code',
    props: true,
    component: () => lazyLoadView(import('../pages/Landing/ResetPassword.vue')),
    meta: {
      title: 'Wachtwoord resetten',
      requiresAuth: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/aanmelden',
    component: () => lazyLoadView(import('../pages/Landing/Register.vue')),
    meta: {
      title: 'Aanmelden',
      requiresAuth: false,
      showFooter: false,
      navbarTopShow: true,
      hideSubbar: true,
      hideSidebar: true
    }
  },
  {
    path: '/quizlet-alternatief',
    component: () => lazyLoadView(import('../pages/Landing/Quizlet-alternatief.vue')),
    meta: {
      title: 'Een goed Quizlet alternatief',
      requiresAuth: false,
      showFooter: true,
      isBlue: true,
      navbarTopShow: true,
      hideSubbar: true,
    }
  },
  {
    path: '/foto-overhoren',
    beforeEnter(to, from, next) {
     window.location.replace("https://ikleeralles.nl/study-buddy")
    }
  },
  {
    path: '/study-buddy',
    component: () => import('../pages/Landing/StudyBuddy.vue'),
    meta: {
      title: 'Een persoonlijke Studie vriend',
      requiresAuth: false,
      showFooter: true,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true,
    },
  },
  {
    path: '/bijles',
    component: () => import('../pages/Landing/Bijles.vue'),
    meta: {
      title: 'Online bijles in elk vak!',
      requiresAuth: false,
      showFooter: true,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true,
    },
  },
  {
    path: '/upload/:shortId/:textType',
    props: true,
    component: () => lazyLoadView(import('../pages/Chat/UploadFromPhone.vue')),
    meta: {
      title: 'Upload een foto',
      requiresAuth: false,
      showFooter: false,
      navbarTopShow: true,
      isBlue: false,
      hideSubbar: true,
    }
  },
  {
    path: '/woordjes-leren',
    component: () => lazyLoadView(import('../pages/Landing/Woordjes-leren.vue')),
    meta: {
      title: 'Woordjes leren met Ikleeralles.nl',
      requiresAuth: false,
      showFooter: true,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true,
    }
  },
  {
    path: '/topografie',
    component: () => lazyLoadView(import('../pages/Landing/Topografie.vue')),
    meta: {
      title: 'Topografie oefenen met Ikleeralles.nl',
      requiresAuth: false,
      showFooter: true,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true
    }
  },
  {
    path: '/blog',
    component: () => lazyLoadView(import('../pages/Landing/Blog/Blog.vue')),
    meta: {
      title: 'Blog',
      requiresAuth: false,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true
    }
  },
  {
    path: '/blog/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Landing/Blog/Post.vue')),
    meta: {
      title: 'Blog',
      requiresAuth: false,
      navbarTopShow: true,
      isBlue: true,
      hideSubbar: true
    }
  },
  {
    path: '/contact',
    component: () => lazyLoadView(import('../pages/Contact.vue')),
    meta: {
      title: 'Contact',
      requiresAuth: true,
      navbarTopShow: true,
    }
  },
  {
    path: '/education',
    component: () => lazyLoadView(import('../pages/UpdateEducation.vue')),
    meta: {
      title: 'Opleiding bijwerken',
      requiresAuth: true,
      hideSubbar: true,
      hideSidebar: true

    },
  },
  //ROUTES WHEN LOGGED IN
  {
    path: '/premium',
    component: () => lazyLoadView(import('../pages/Premium/Premium.vue')),
    meta: {
      title: 'Premium',
      requiresAuth: true
    }
  },
  {
    path: '/suggestion',
    component: () => lazyLoadView(import('../pages/Suggestion.vue')),
    meta: {
      title: 'Suggestie',
      requiresAuth: false,
    },
  },
  {
    path: '/welcome/:index?',
    props: route => ({ index: Number(route.params.index) }),
    component: () => lazyLoadView(import('../pages/Welcome.vue')),
    meta: {
      title: 'Welkom',
      requiresAuth: true,
      hideSidebar: true,
    }
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => lazyLoadView(import('../pages/Dashboard.vue')),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/search',
    component: () => lazyLoadView(import('../pages/Search.vue')),
    meta: {
      title: 'Zoeken',
      requiresAuth: true,
    },
  },
  {
    path: '/method',
    component: () => lazyLoadView(import('../pages/Lists/Method.vue')),
    meta: {
      title: 'Boek kiezen',
      requiresAuth: true
    }
  },
  {
    name: 'create-list',
    path: '/create-list',
    props: true,
    component: () => lazyLoadView(import('../pages/Lists/CreateList.vue')),
    meta: {
      title: 'Lijst maken',
      requiresAuth: true,
    }
  },
  {
    path: '/scan-list',
    component: () => lazyLoadView(import('../pages/Scan/ScanList.vue')),
    meta: {
      title: 'Lijst scannen',
      requiresAuth: true,
    },
  },
  {
    path: '/scan/:shortId',
    props: true,
    component: () => lazyLoadView(import('../pages/Scan/ScanOnPhone.vue')),
    meta: {
      title: 'Afbeeldingen uploaden',
      requiresAuth: false,
      hideSidebar: true,
    },
  },
  {
    path: '/chat',
    component: () => import('../pages/Chat/Chat.vue'),
    meta: {
      title: 'StudyBuddy',
      requiresAuth: true,
      hideSubbar: true,
      // subbarContent: () => import('../pages/Chat/components/SubbarContent.vue'),
    },
  },
  {
    path: '/chat/share/:code',
    props: true,
    component: () => import('../pages/Chat/JoinChat.vue'),
    meta: {
      title: 'StudyBuddy',
      requiresAuth: true,
      hideSubbar: true,
    }
  },
  {
    path: '/chat/:id',
    props: true,
    component: () => import('../pages/Chat/Chat.vue'),
    meta: {
      title: 'StudyBuddy',
      requiresAuth: true,
      hideSubbar: true,
    }
  },
  {
    path: '/zwijsen',
    component: () => import( '../pages/Landing/Zwijsen.vue'),
    meta: {
      title: 'StudyBuddy',
    }
  },
  // {
  //   path: '/import-list',
  //   component: () => lazyLoadView(import('../pages/Lists/Import.vue')),
  //   meta: {
  //     title: 'Lijst importeren',
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/results',
    component: () => lazyLoadView(import('../pages/Results/Results.vue')),
    meta: {
      title: 'Resultaten',
      requiresAuth: true,
    },
  },
  {
    name: 'edit-list',
    path: '/edit-list/:id',
    props: route => ({ id: Number(route.params.id) }), //To parse string to number
    component: () => lazyLoadView(import('../pages/Lists/EditList.vue')),
    meta: {
      title: 'Lijst bewerken',
      requiresAuth: true,
    },
  },
  {
    path: '/view-list/:id',
    props: route => ({ id: Number(route.params.id) }),
    component: () => lazyLoadView(import('../pages/Lists/ViewList.vue')),
    meta: {
      title: 'Lijst bekijken',
      requiresAuth: true,
    },
  },
  {
    path: '/bin',
    props: true,
    component: () => lazyLoadView(import('../pages/Lists/Bin.vue')),
    meta: {
      title: 'Verwijderde lijsten',
      requiresAuth: true,
    },
  },
  {
    path: '/test/combine/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Test/TestCombine.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/multiple-choice/:id',
    props: route => ({ id: route.params.id, type: "multiple-choice" }),
    component: () =>
      lazyLoadView(import('../pages/Test/Test.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/mind/:id',
    props: route => ({ id: route.params.id, type: "mind" }),
    component: () =>
      lazyLoadView(import('../pages/Test/Test.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/planning/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Test/TestPlanningItem.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/typing/:id',
    props: route => ({ id: route.params.id, type: "typing" }),
    component: () => lazyLoadView(import('../pages/Test/Test.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/random/:id',
    props: route => ({ id: route.params.id }),
    component: () => lazyLoadView(import('../pages/Test/Test.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/test/sentence/:id',
    props: route => ({ id: route.params.id, type: "word-in-sentence" }),
    component: () => lazyLoadView(import('../pages/Test/Test.vue')),
    meta: {
      title: 'Lijst overhoren',
      requiresAuth: true,
      hideSidebar: true
    }
  },
  {
    path: '/prepare/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Test/Prepare.vue')),
    meta: {
      title: 'Overhoorinstellingen',
      requiresAuth: true,
    },
  },
  {
    path: '/folder/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Folders/Folder.vue')),
    meta: {
      title: 'Folder',
      requiresAuth: true,
    },
  },
  {
    name: 'folders',
    path: '/folders',
    props: true,
    component: () => lazyLoadView(import('../pages/Folders/Folders.vue')),
    meta: {
      title: 'Mappen',
      requiresAuth: true
    },
  },
  {
    name: 'groups',
    path: '/groups',
    props: true,
    component: () => lazyLoadView(import('../pages/Groups/Groups.vue')),
    meta: {
      title: 'Groepen',
      requiresAuth: true,
    },
  },
  {
    name: 'group.members',
    path: '/group/:id/members',
    props: true,
    component: () => lazyLoadView(import('../pages/Groups/Members.vue')),
    meta: {
      title: 'Groep\'s Leden',
      requiresAuth: true,
    }
  },
  {
    name: 'group.member',
    path: '/group/:id/members/:member',
    props: true,
    component: () => lazyLoadView(import('../pages/Groups/Member.vue')),
    meta: {
      title: 'Groep\'s Leden',
      requiresAuth: true,
    }
  },
  {
    name: 'group',
    path: '/group/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Groups/Group.vue')),
    meta: {
      title: 'Groep',
      requiresAuth: true,
    },
  },
  {
    path: '/group/:id/join',
    props: true,
    component: () => lazyLoadView(import('../pages/Groups/Join.vue')),
    meta: {
      title: 'Lid worden van groep',
      requiresAuth: true,
    },
  },
  {
    path: '/books',
    props: true,
    component: () => lazyLoadView(import('../pages/Book/Books.vue')),
    meta: {
      title: 'Boeken',
      requiresAuth: true
    },
  },
  {
    path: '/book/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Book/Book.vue')),
    meta: {
      title: 'Boek',
      requiresAuth: true
    },
  },
  {
    path: '/profile/:username',
    props: true,
    component: () => lazyLoadView(import('../pages/Profile/Profile.vue')),
    meta: {
      title: 'Profiel',
      requiresAuth: true,
      hideSubbar: true
    },
  },
  {
    path: '/planning',
    component: () => lazyLoadView(import('../pages/Planning/Plannings.vue')),
    meta: {
      title: 'Planning',
      requiresAuth: true,
    },
  },
  {
    path: '/planning/:id',
    props: true,
    component: () => lazyLoadView(import('../pages/Planning/Planning.vue')),
    meta: {
      title: 'Planning',
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    props: true,
    component: () => lazyLoadView(import('../pages/Settings/Settings.vue')),
    name: 'settings',
    meta: {
      title: 'Instellingen',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        alias: 'account',
        meta: {
          title: 'Account instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Account.vue'))
      },
      {
        name: 'settings-education',
        path: 'education',
        meta: {
          title: 'Opleiding instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Education.vue'))
      },
      {
        name: 'settings-theme',
        path: 'theme',
        meta: {
          title: 'Website instellingen',
        },
        component: () => lazyLoadView(import('../pages/Settings/Theme.vue'))
      }
    ]
  },
  {
    name: 'unauthorized',
    path: '/unauthorized',
    props: true,
    meta: {
      title: "Unauthorized",
      hideSubbar: true,
    },
    component: () => lazyLoadView(import('../pages/Unauthorized.vue')),
  },
  {
    path: '/404',
    name: '404',
    component: require('../pages/404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
    meta: {
      title: "404 Not found",
      hideSubbar: true,
    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '/404'
  }
]
